const hexToRGB = (hex) => {
  let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
  if (!regex.test(hex)) {
    throw new Error("invalid hex code");
  }
  hex = hex.slice(1);
  let diff = hex.length / 3;
  let rgb = {
    r: parseInt(hex.slice(0, 0 + diff), 16),
    g: parseInt(hex.slice(diff, 2 * diff), 16),
    b: parseInt(hex.slice(2 * diff), 16),
  };

  // console.log(rgb);
  return rgb;
};

function rgbToHSL({ r, g, b }) {
  // Normalize RGB values to the range [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    // Grayscale
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
    }

    h /= 6;
  }

  const hsl = {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };

  // console.log(hsl);
  return hsl;
}

// function hslToRGB({ h, s, l }) {
//   h /= 360;
//   s /= 100;
//   l /= 100;

//   let r, g, b;

//   if (s === 0) {
//     r = g = b = l;
//   } else {
//     const hue2rgb = (p, q, t) => {
//       if (t < 0) t += 1;
//       if (t > 1) t -= 1;
//       if (t < 1 / 6) return p + (q - p) * 6 * t;
//       if (t < 1 / 2) return q;
//       if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
//       return p;
//     };

//     const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//     const p = 2 * l - q;

//     r = hue2rgb(p, q, h + 1 / 3);
//     g = hue2rgb(p, q, h);
//     b = hue2rgb(p, q, h - 1 / 3);
//   }

//   return {
//     r: Math.round(r * 255),
//     g: Math.round(g * 255),
//     b: Math.round(b * 255),
//   };
// }

class Color {
  constructor(color) {
    // console.log(color)
    if(!color){
      // console.log(color)/
      color = {h:0, s:100, l:100};
    }
    // console.log(typeof color)
    if (typeof color === "object") {
      // console.log(typeof color)
      if ((color.h>=-360 && color.h<=360) && (color.s>=0 && color.s<=100) && (color.l>=0 && color.l<=100)) {
        // console.log(typeof color)
        this.h = color.h<0? 360+color.h : color.h;
        this.s = color.s;
        this.l = color.l;
      } else if ((color.r>=0 && color.r<=255)  && (color.g>=0 && color.g<=255) && (color.b>=0 && color.b<=255)) {
        const hsl = rgbToHSL(color);
        this.h = hsl.h;
        this.s = hsl.s;
        this.l = hsl.l;
      }
      else{
        throw new Error("invalid color format");
      }
      return;
    }
    color = color.replaceAll(" ", "").toLowerCase();
    if (color[0] === "#") {
      const rgb = hexToRGB(color);
      // this.r = rgb.r;
      // this.g = rgb.g;
      // this.b = rgb.b;
      const hsl = rgbToHSL(rgb);
      this.h = hsl.h;
      this.s = hsl.s;
      this.l = hsl.l;
    } else if (color[0] === "r") {
      let matchColors = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
      if (!matchColors.test(color)) {
        throw new Error("invalid color format");
      }
      const match = color.match(/(\d+),(\d+),(\d+)/);
      const r = parseInt(match[1]);
      const g = parseInt(match[2]);
      const b = parseInt(match[3]);
      // return { r, g, b };
      //   color = color.split(",");
      const rgb = { r, g, b };
      // const rgb = {r: parseInt(color[0].slice(4)), g: parseInt(color[1]), b: parseInt(color[2].split(")")[0])}
      // this.r = rgb.r;
      // this.g = rgb.g;
      // this.b = rgb.b;
      const hsl = rgbToHSL(rgb);
      this.h = hsl.h;
      this.s = hsl.s;
      this.l = hsl.l;
      // console.log(rgb)
    } else if (color[0] === "h") {
      let matchColors = /hsl\((\d{1,3}),(\d{1,3})%?,(\d{1,3})%?\)/;
      if (!matchColors.test(color)) {
        throw new Error("invalid color format");
      }

      const match = color.match(/(\d+),(\d+)%?,(\d+)%?/);
      // if (match) {
      const h = parseInt(match[1]);
      const s = parseInt(match[2]);
      const l = parseInt(match[3]);
      const hsl = { h, s, l };
      // console.log(hsl);
      this.h = hsl.h;
      this.s = hsl.s;
      this.l = hsl.l;

      //   const rgb = hslToRGB(hsl);

      //   this.r = rgb.r;
      //   this.g = rgb.g;
      //   this.b = rgb.b;

      // }
    }
  }

  //   hsl(h=this.h, s=this.s, l = this.l) {
  //     return `hsl(${h},${s}%,${l}%)`;
  //   }
  //   hsla(h=this.h, s=this.s, l = this.l, a=1) {
  //     return `hsl(${h},${s}%,${l}%,${a})`;
  //   }
  darken(n) {
    n = Math.abs(n);
    // return `hsl(${this.h},${this.s}%,${this.l - n < 0 ? 0 : this.l - n}%)`;
    return {
      h: this.h,
      s: this.s,
      l: this.l - n < 0 ? 0 : this.l - n,
    };
  }
  lighten(n) {
    n = Math.abs(n);
    // return `hsl(${this.h},${this.s}%,${this.l + n > 100 ? 100 : this.l + n}%)`;
    return {
      h: this.h,
      s: this.s,
      l: this.l + n > 100 ? 100 : this.l + n,
    };
  }
  setBrightness(n) {
    return `hsl(${this.h},${this.s}%,${n < 0 ? 0 : n % 100}%)`;
  }
  adjustHue(n) {
    //   n = Math.abs(n)
    return `hsl(${(this.h + n) % 360},${this.s}%,${this.l}%)`;
  }
  setHue(n) {
    //   n = Math.abs(n)
    return `hsl(${n % 360},${this.s}%,${this.l}%)`;
  }
}

export const hsl = ({ h, s, l }, a = 1) => {
  return `hsl(${h},${s}%,${l}%,${a})`;
};

export default Color;

// hexToRGB("#ffffff");
// console.log(new Color({h:200, s: 51, l:89}));
