import React, { useState } from "react";
// import ColorContext from "../../../context/ColorContext/ColorContext"
import Color from "../../../functions/colors";
import { Link } from "react-router-dom";
const SideNavBar = (props) => {
  // const colorContext = useContext(ColorContext)
  const color = new Color(props.color);
  const [isSideNavBarOpen, setIsSideNavBarOpen] = useState(false);
  const toggleSideNavBar = () => {
    console.log(color)
    const navbar = document.getElementById(props.id);
    const width = parseInt(
      window.getComputedStyle(navbar).getPropertyValue("width").split("p")[0]
    );
    // console.log(width);
    if (width > 0) {
      navbar.style.width = "0%";
      setIsSideNavBarOpen(false);
    } else {
      navbar.style.width = "100%";
      setIsSideNavBarOpen(true);
    }
  };



  return (
    <>
      <button
        className="side-nav-bar__btn side-nav-bar__btn--toggle"
        onClick={toggleSideNavBar}
        style={{
          "--h": `${color.h}`,
          "--s": `${color.s}%`,
          "--l": `${color.l}%`,
        }}
      >
        {isSideNavBarOpen ? (
          <span className="material-symbols-outlined">close</span>
        ) : (
          <span className="material-symbols-outlined">menu</span>
        )}
      </button>
      <div className="side-nav-bar" id={`${props.id}`}>
        <button
          className="side-nav-bar__btn side-nav-bar__btn--close"
          onClick={toggleSideNavBar}
          style={{
            "--h": `${color.h}`,
            "--s": `${color.s}%`,
            "--l": `${color.l}%`,
          }}
        >
          {isSideNavBarOpen ? (
            <span className="material-symbols-outlined">close</span>
          ) : (
            <span className="material-symbols-outlined">menu</span>
          )}
        </button>
        
        <h3 className="side-nav-bar__title">AeroLink</h3>

        <div className="side-nav-bar__menu">


            {props.menu.map(menuItem=>{
              if(menuItem.type==="link"){
                return(
                  <Link to={menuItem.link} className="side-nav-bar__menu__item side-nav-bar__link" onClick={toggleSideNavBar} key={JSON.stringify(menuItem)}>{menuItem.text}</Link>
                )
              }
              return(null)
            })}


        </div>

      </div>
    </>
  );
};

export default SideNavBar;
