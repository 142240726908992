export const noAuthNavBar = [
    {
        text: "home",
        type: "link",
        link: "/"
    },
    {
        text: "login",
        type: "link",
        link: "/login"
    },
    {
        text: "register",
        type: "link",
        link: "/register"
    },

]


export const authNavBar = [
    {
        text: "dashboard",
        type: "link",
        link: "/dashboard"
    },
    {
        text: "switch account",
        type: "link",
        link: "/switch-account"
    },
    {
        text: "settings",
        type: "link",
        link: "/settings"
    },
    {
        text: "logout",
        type: "link",
        link: "/logout"
    },

]