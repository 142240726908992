import React from "react";
import "./_button3d.scss"
import Color from "../../../functions/colors";

const Button3D = (props) => {
  const color = new Color(props.color);
  return (
    <button
      onClick={props.method}
      className={`button3d ${
        props.focus ? "button3d--focus" : "button3d--dull"
      } ${props.class}`}
      style={{
        "--h": `${color.h}`,
        "--s": `${color.s}%`,
        "--l": `${color.l}%`,
      }}
    >
      {props.text}
    </button>
  );
};

export default Button3D;
