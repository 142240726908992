import React from "react";
import "./_static_neon_button.scss"
import Color from "../../../functions/colors";

const StaticNeonButton = (props) => {
  const color = new Color(props.color);
  return (
    <button
      onClick={props.onClick}
      className={`static-neon-button ${props.class}`}
      style={{
        "--h": `${color.h}`,
        "--s": `${color.s}%`,
        "--l": `${color.l}%`,
      }}
    >
      <span>{props.text}</span>
    </button>
  );
};

export default StaticNeonButton;
