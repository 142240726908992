import React, { useContext, useEffect, useState } from "react";
import FormHeading from "../../components/Headings/FormHeading";
import { GlassTextBox, StaticNeonButton } from "../../components";
import ColorContext from "../../context/ColorContext/ColorContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hsl } from "../../functions/colors";

const LoginForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const autoLogin = searchParams.get('autoLogin');
  


  const colorContext = useContext(ColorContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(()=>{
    if(autoLogin){
      const loginData = JSON.parse(localStorage.getItem("aerolink_creds"))
      console.log(loginData)
      if(!loginData){
        return
      }
      setUsername(loginData.username)
      setPassword(loginData.password)
      // setTimeout(() => {
      //   localStorage.removeItem("aerolink_creds")
      // }, 5000);
    }
  },[autoLogin])


  useEffect(()=>{
    const authToken = localStorage.getItem("aerolink_current_authToken")
    if(authToken){
      setIsLoggedIn(true)
    }
  },[])

  const userLogin = async (e) => {
    localStorage.removeItem("aerolink_creds")
    const loginData = { username, password }

    if (e) {
      e.preventDefault();
    }

    const aerolinkArray = JSON.parse(localStorage.getItem("aerolink_account_array"))
      if(aerolinkArray && aerolinkArray.length>=1){
        for(let i=0; i<aerolinkArray.length; i++){
          if(aerolinkArray[i].data.username===loginData.username){
            return alert("already logged in")
          }
        }
      }



    console.log("login", { username });
    try {
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/account/login`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
          method: "POST",
        }   
      );
      const response = await res.json();
      console.log(response);
      if(!response.status){
        setIsLoggedIn(false)
        return alert(response.message);

      }

      localStorage.setItem("aerolink_current_authToken", response.authToken);
      localStorage.setItem("aerolink_current_user_data", JSON.stringify(response.data));
      const aerolinkArray = JSON.parse(localStorage.getItem("aerolink_account_array"))
      if(!aerolinkArray || aerolinkArray.length<1){
        localStorage.setItem("aerolink_account_array", JSON.stringify([{authToken: response.authToken, data: response.data}]))
      }
      else{
          aerolinkArray.push({authToken: response.authToken, data: response.data})
          localStorage.setItem("aerolink_account_array", JSON.stringify(aerolinkArray))
      }
      setIsLoggedIn(true)
      alert(response.message)
      navigate("/dashboard")
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="register-form form" onSubmit={userLogin}>
      <FormHeading>{isLoggedIn? "add another account": "Ready to Take Off? Login Now!"}</FormHeading>
      <GlassTextBox
        type={"text"}
        id={"loginUsername"}
        class={"form__textbox"}
        placeholder={"username"}
        value={username}
        setValue={setUsername}
      ></GlassTextBox>
      <GlassTextBox
        type={"password"}
        id={"loginPassword"}
        class={"form__textbox"}
        placeholder={"password"}
        value={password}
        setValue={setPassword}
      ></GlassTextBox>
      <StaticNeonButton
        text={"Login"}
        class={"login-form__btn"}
        color={colorContext.secondaryColor.lighten(20)}
        onClick={userLogin}
      ></StaticNeonButton>
      <p style={{ color: hsl(colorContext.secondaryColor.lighten(35)) }}>
        New to AeroLink? <Link to={"/register"}>Click Here</Link> to register.
      </p>
    </form>
  );
};

export default LoginForm;
