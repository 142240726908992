import React, { useState } from 'react'
import { GlassTextBox } from '../../components'

const ProfileForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    // const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")


    const updateProfile = async (e)=>{
        const userData = JSON.parse(localStorage.getItem("aerolink_current_user_data"))
        e.preventDefault();

        const profileData = {
            first_name: firstName,
            last_name: lastName,
            phone: phone
        }

        const res = await fetch(`${process.env.REACT_APP_AEROLINK_API_URL}/account?username=${userData.username}`, {
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify(profileData),
            method: "PUT"
        })
        const response = await res.json()
        if(!response.status){
            return alert(response.message)
        }
        setFirstName("")
        setPhone("")
        setLastName("")
    }
  return (
    <form className='form' onSubmit={updateProfile}>
      <GlassTextBox
        type={"text"}
        id={"profileFirstName"}
        class={"form__textbox"}
        placeholder={"first name"}
        value={firstName}
        setValue={setFirstName}
      ></GlassTextBox>
      <GlassTextBox
        type={"text"}
        id={"profileLastName"}
        class={"form__textbox"}
        placeholder={"last name"}
        value={lastName}
        setValue={setLastName}
      ></GlassTextBox>
      {/* <GlassTextBox
        type={"text"}
        id={"profileEmail"}
        class={"form__textbox"}
        placeholder={"email"}
        value={email}
        setValue={setEmail}
      ></GlassTextBox> */}
      <GlassTextBox
        type={"number"}
        id={"profilePhone"}
        class={"form__textbox"}
        placeholder={"phone"}
        value={phone}
        setValue={setPhone}
      ></GlassTextBox>

      <button type="submit">update</button>
    </form>
  )
}

export default ProfileForm
