import React, { useEffect, useRef, useState } from "react";
import ContactList from "../../containers/ContactList/ContactList";
import ChatView from "../../containers/ChatView/ChatView";
import { io } from "socket.io-client";
import { GlassContactCard } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { checkLoginStatus } from "../../functions/auth.function";

const DashBoard = () => {
  const navigate = useNavigate()
  const socket = useRef();
  // receiver
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [contactList, setContactList]=useState([])
  // const []

  useEffect(() => {
    checkLoginStatus()
      .then((isLoggedIn) => {
        if (!isLoggedIn) {
          console.log("User is logged in.");
          navigate("/login")
        } else {
        }
      })
      .catch((error) => {
        console.error("Error checking login status:", error);
      });
  }, [navigate]);

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem("aerolink_current_user_data")
    );
    if(userData){
      setCurrentUser(userData.username);
      console.log(userData.contacts)
      // setContactList(userData.contacts)
      socket.current = io(process.env.REACT_APP_AEROLINK_API_URL);
      // if(currentUser){
      console.log("add-user");
      socket.current.emit("add-user", userData.username);

      if(userData.contacts.length>0){
      fetch(`${process.env.REACT_APP_AEROLINK_API_URL}/account/contactlist?username=${userData.username}`).then(res=>res.json()).then(response=>{
        if(!response.status){
          return alert(response.message)
        }
        console.log(response.data)
        setContactList(response.data)
      })}
    }

    // }
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard__left">
        <div className="dashboard__profile">
          <GlassContactCard
            data={{
              username: currentUser ? currentUser : "",
              image_url: "https://picsum.photos/360/230",
            }}
          ></GlassContactCard>

          <div className="dashboard__btn-group">
            <Link to={"/settings/contacts"} className="dashboard__btn" >+</Link>
          </div>
        </div>
        <ContactList setCurrentChat={setCurrentChat} contactList={contactList}></ContactList>
      </div>
      <div className="dashboard__right">
        <ChatView
          currentChat={currentChat}
          currentUser={currentUser}
          socket={socket}
        ></ChatView>
      </div>

      {/* <div>{localStorage.getItem("aerolink_current_authToken")}</div> */}
      {/* <br />
      <br />
      <br /> */}
      {/* <div>{localStorage.getItem("aerolink_current_user_data")}</div> */}
      {/* <br />
      <br />
      <br /> */}
      {/* <div>aerolink_account_array</div>
      <div>aerolink_account_array</div>
      <div>aerolink_account_array</div> */}
      {/* <div>{localStorage.getItem("aerolink_account_array")}</div> */}
    </div>
  );
};

export default DashBoard;
