import { useContext, useEffect, useState } from "react";
import "./css/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DashBoard, HomePage, SettingsPage, SwitchAccount } from "./pages";
import { LoginForm, RegisterForm, Title } from "./containers";
// import GlassPopUp from "./templates/GlassPopUp/GlassPopUp";
import { GlassPopUp, ContentContainer } from "./templates";
// import ContentContainer from "./templates/ContentContainer/ContentContainer";
import { SideNavBar } from "./components";
import ColorContext from "./context/ColorContext/ColorContext";
import { authNavBar, noAuthNavBar } from "./data/navbar.data";
import { checkLoginStatus } from "./functions/auth.function";
import OTPVerifier from "./containers/OTPVerifier/OTPVerifier";

function App() {
  const colorContext = useContext(ColorContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkLoginStatus()
      .then((isLoggedIn) => {
        if (isLoggedIn) {
          // console.log("User is logged in.");
          // navigate("/dashboard")
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      })
      .catch((error) => {
        console.error("Error checking login status:", error);
      });
  }, []);
  return (
    <BrowserRouter>
      <main className="App">
        {/* <h1>hello world</h1> */}

        {isLoggedIn ? (
          <SideNavBar
            color={colorContext.secondaryColor}
            menu={authNavBar}
          ></SideNavBar>
        ) : (
          <SideNavBar
            color={colorContext.secondaryColor}
            menu={noAuthNavBar}
          ></SideNavBar>
        )}

        <Routes>
          <Route
            path="/"
            element={
              <HomePage child={<Title></Title>}></HomePage>
            }
          ></Route>

          <Route
            path="/register"
            element={
              <HomePage
                child={
                  <GlassPopUp
                    child={<RegisterForm></RegisterForm>}
                    class={"homepage__glass"}
                  ></GlassPopUp>
                }
              ></HomePage>
            }
          ></Route>

          <Route
            path="/login"
            element={
              <HomePage
                child={
                  <GlassPopUp
                    child={<LoginForm></LoginForm>}
                    class={"homepage__glass"}
                  ></GlassPopUp>
                }
              ></HomePage>
            }
          ></Route>


          <Route
            path="/switch-account"
            element={
              <ContentContainer
                child={
                  <SwitchAccount></SwitchAccount>
                }
              ></ContentContainer>
            }
          ></Route>

          <Route
            path="/auth/otp"
            element={
              <HomePage
                child={
                  <GlassPopUp
                    child={<OTPVerifier></OTPVerifier>}
                    class={"homepage__glass"}
                  ></GlassPopUp>
                }
              ></HomePage>
            }
          ></Route>

          <Route
            path="/dashboard"
            element={
              <ContentContainer
                // logo={false}
                child={<DashBoard></DashBoard>}
              ></ContentContainer>
            }
          ></Route>


          <Route
            path="/settings"
            element={
              <ContentContainer
                // logo={false}
                child={<SettingsPage></SettingsPage>}
              ></ContentContainer>
            }
          ></Route>
          <Route
            path="/settings/:setting_type"
            element={
              <ContentContainer
                // logo={false}
                child={<SettingsPage></SettingsPage>}
              ></ContentContainer>
            }
          ></Route>
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
