import { styled } from "styled-components";


const FormHeading = styled.h2`
font-size: 2rem;
text-align:center;
position: relative;
margin: 0rem 0rem 4rem;
color: white;
// font-family: $font-audiowide;
font-weight: 500;
text-transform: capitalize;

&::after{
    content: " ";
    position: absolute;
    bottom: -2rem;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid white;
    outline: 1px solid white;
    outline-offset: 2px;
    border-radius: 50rem;
}
`

export default FormHeading;