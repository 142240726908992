import React, { useEffect, useState } from "react";

const ContactSettings = () => {
  const userData = JSON.parse(
    localStorage.getItem("aerolink_current_user_data")
  );
  const [newUsername, setNewUsername] = useState("");
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);

  const currentUser = userData.username;


  useEffect(()=>{
    fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request/from?username=${currentUser}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      ).then(res=>res.json()).then(response=>{
        if(!response.status){
            alert(response.message)
        }
        else{
            setSentRequests(response.data)
        }
      })
  },[currentUser])
  useEffect(()=>{
    fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request/to?username=${currentUser}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      ).then(res=>res.json()).then(response=>{
        if(!response.status){
            alert(response.message)
        }
        else{
            setReceivedRequests(response.data)
        }
      })
  },[currentUser])

  const handleNewUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  const sendAddRequest = async (e) => {
    e.preventDefault();
    try {
      const data = { from: currentUser, to: newUsername };
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          method: "POST",
        }
      );

      const response = await res.json();

      if (!response.status) {
        alert(response.message);
        return;
      }
      setNewUsername("")
      const newSentRequests = [response.data, ...sentRequests];
      setSentRequests(newSentRequests);
    } catch (error) {
      console.log(error);
    }
  };


  const cancelRequest = async (to) => {
    // e.preventDefault();
    try {
      const data = { from: currentUser, to: to };
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request/request`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          method: "DELETE",
        }
      );

      const response = await res.json();
      console.log(response)

      if (!response.status) {
        alert(response.message);
        return;
      }
    //   setNewUsername("")
      const newSentRequests = sentRequests.filter(request=>request.to!==to);
      setSentRequests(newSentRequests);
    } catch (error) {
      console.log(error);
    }
  };
  const acceptRequest = async (from) => {
    // e.preventDefault();
    try {
      const data = { from: from, to: currentUser };
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request/request`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          method: "POST",
        }
      );

      const response = await res.json();
      console.log(response)

      if (!response.status) {
        alert(response.message);
        return;
      }
    //   setNewUsername("")
    localStorage.setItem("aerolink_current_user_data", JSON.stringify(response.userData));
    
      const newSentRequests = receivedRequests.filter(request=>request.from!==from);
      setReceivedRequests(newSentRequests);
    } catch (error) {
      console.log(error);
    }
  };
  const denyRequest = async (from) => {
    // e.preventDefault();
    try {
      const data = { from: from, to: currentUser };
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/contact-request/request`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          method: "PUT",
        }
      );

      const response = await res.json();
      console.log(response)

      if (!response.status) {
        alert(response.message);
        return;
      }
    //   setNewUsername("")
      const newSentRequests = receivedRequests.filter(request=>request.from!==from);
      setReceivedRequests(newSentRequests);
    } catch (error) {
      console.log(error);
    }
  };

  

  return (
    <div className="contact-setting">
      <div className="contact-setting__container contact-setting__container--send">
        <form onSubmit={sendAddRequest}>
          <input
            type="text"
            name=""
            id=""
            value={newUsername}
            onChange={handleNewUsernameChange}
            placeholder="username"
          />
          <button type="submit">send</button>
        </form>
      </div>

      <div className="contact-setting__container contact-setting__container--sent">
        <div className="contact-setting__requests-container">
          {sentRequests.map((request) => {
            return (
              <div className="contact-setting__request__card" key={JSON.stringify(request)}>
                <p className="contact-setting__request__text">{`Request sent to ${request.to}`}</p>
                <button className="contact-setting__request__btn contact-setting__request__btn--red" onClick={()=>{cancelRequest(request.to)}}>cancel</button>
              </div>
            );
          })}
        </div>
        {/* {JSON.stringify(sentRequests)} */}
      </div>
      <div className="contact-setting__container contact-setting__container--accept">
      <div className="contact-setting__requests-container">
          {receivedRequests.map((request) => {
            return (
              <div className="contact-setting__request__card" key={JSON.stringify(request)}>
                <p className="contact-setting__request__text">{`${request.from} wants to connect`}</p>
                <button className="contact-setting__request__btn contact-setting__request__btn--blue" onClick={()=>{acceptRequest(request.from)}}>accept</button>
                <button className="contact-setting__request__btn contact-setting__request__btn--red" onClick={()=>{denyRequest(request.from)}}>cancel</button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactSettings;
