import React from "react";
import "./glass_contact_card.scss";

const GlassContactCard = (props) => {
  return (
    <div className="glass-contact-card">
      <div className="glass-contact-card__image" style={{backgroundImage: `url(${props.data.image_url})`}}>
      </div>
      <div className="glass-contact-card__content">
      <p className="glass-contact-card__content__username">{props.data.username}</p>
      </div>
    </div>
  );
};

export default GlassContactCard;
