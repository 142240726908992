import React, { useContext, useEffect, useState } from "react";
import FormHeading from "../../components/Headings/FormHeading";
import {
  GlassTextBox,
  HeadingWithLine,
  StaticNeonButton,
} from "../../components";
import ColorContext from "../../context/ColorContext/ColorContext";
import { useNavigate } from "react-router-dom";
import { checkLoginStatus } from "../../functions/auth.function";
import { validateEmail } from "../../functions/validator.functions";

const RegisterForm = () => {
  const [email, setEmail] = useState("");
  // const [openOTPVerifier, setOpenOTPVerifier] = useState(false);
  const navigate = useNavigate();

  const colorContext = useContext(ColorContext);

  useEffect(() => {
    checkLoginStatus()
      .then((isLoggedIn) => {
        if (isLoggedIn) {
          console.log("User is logged in.");
          navigate("/dashboard")
        } else {
        }
      })
      .catch((error) => {
        console.error("Error checking login status:", error);
      });
  }, [navigate]);

  // const validateEmail = (email) => {
  //   if (email !== "vidit") {
  //     return false;
  //   }
  //   return true;
  // };

  const registerAccount = async (e) => {
    e.preventDefault();
    // alert(e);
    console.log("register", { email });
    try {
      const res = await fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/account`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
          method: "POST",
        }
      );
      const response = await res.json();
      console.log(response);
      if (!response.status) {
        return alert(response.message);
      }
      alert(response.message);
        localStorage.setItem("aerolink_creds", JSON.stringify({username: response.data.username, password: response.data.password }))

      navigate(`/auth/otp?email=${response.data.email}`);
      // setOpenOTPVerifier(true)
    } catch (error) {
      console.log(error);
    }
  };

  const loginBtnHandler = () => {
    navigate("/login");
  };
  return (
    <form className="register-form form" onSubmit={registerAccount}>
      <FormHeading>Embrace AeroLink's Connectivity</FormHeading>
      <GlassTextBox
        type={"text"}
        id={"registerEmail"}
        class={"form__textbox"}
        placeholder={"email"}
        value={email}
        setValue={setEmail}
        validator={validateEmail}
        color={colorContext.primaryColor.lighten(50)}
        opacity={"0.1"}
      ></GlassTextBox>
      <StaticNeonButton
        text={"Register"}
        class={"register-form__btn"}
        color={colorContext.secondaryColor.lighten(20)}
        onClick={registerAccount}
      ></StaticNeonButton>
      <HeadingWithLine
        text={"OR"}
        color={colorContext.secondaryColor.lighten(50)}
      ></HeadingWithLine>
      <StaticNeonButton
        text={"Login"}
        class={"register-form__btn"}
        color={colorContext.primaryColor.lighten(20)}
        onClick={loginBtnHandler}
      ></StaticNeonButton>
    </form>
  );
};

export default RegisterForm;
