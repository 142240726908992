import React, { useState } from "react";
import { GlassTextBox } from "../../components";
import FormHeading from "../../components/Headings/FormHeading";
import { useLocation, useNavigate } from "react-router-dom";

const OTPVerifier = () => {
    const [otp, setOTP] = useState("")
    const location = useLocation();
    const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

    const verifyOTP = async (e)=>{
        e.preventDefault();
        alert(email)
        const res = await fetch(`${process.env.REACT_APP_AEROLINK_API_URL}/account/auth/otp`,{
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify({email, otp: `${otp}`}),
            method: "POST"
        })
        const response = await res.json();
        if(!response.status){
            return alert(response.message)
        }
        alert(response.message)
        // localStorage.setItem("aerolink_creds", JSON.stringify({email, }))
        navigate("/login?autoLogin=true");
    }
  return (
    <form className="form otp-verifier" onSubmit={verifyOTP}>
        <FormHeading>an OTP has been sent<br />check your email</FormHeading>
      <GlassTextBox
        type={"number"}
        id={"otp"}
        class={"form__textbox"}
        placeholder={"OTP"}
        value={otp}
        setValue={setOTP}
      ></GlassTextBox>
    </form>
  );
};

export default OTPVerifier;
