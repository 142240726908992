import React from 'react'
import { GlassContactCard } from '../../components'

const ContactList = (props) => {
//   const [contactList, setContactList]=useState([])

//   useEffect(()=>{
//     setContactList([
//         {
//           username: "vimo",
//           image_url: "https://picsum.photos/300/200",
//         },
//         {
//           username: "vimo6728",
//           image_url: "https://picsum.photos/400/250",
//         },
//         {
//           username: "vimo8645",
//           image_url: "https://picsum.photos/350/220",
//         },
//         {
//           username: "vimo5688",
//           image_url: "https://picsum.photos/320/240",
//         },
//         {
//           username: "vimo351",
//           image_url: "https://picsum.photos/280/180",
//         },
//         {
//           username: "vimo5593",
//           image_url: "https://picsum.photos/420/260",
//         },
//         {
//           username: "vimo2725",
//           image_url: "https://picsum.photos/380/240",
//         },
//         {
//           username: "vimo1702",
//           image_url: "https://picsum.photos/310/190",
//         },
//         {
//           username: "vimo6727",
//           image_url: "https://picsum.photos/290/210",
//         },
//         {
//           username: "vimo1185",
//           image_url: "https://picsum.photos/360/230",
//         },
//       ])
//   },[])


  const handleChatChange = (username, index)=>{
    props.setCurrentChat(username)
    const contactCards = document.getElementsByClassName("contact-list__card")
    for(let i=0; i<contactCards.length; i++){
        if(i===index){
            contactCards[i].classList.add("contact-list__card--selected")
        }else{
            contactCards[i].classList.remove("contact-list__card--selected")
        }

    }

  }
  return (
    <div className='contact-list'>
        {/* <GlassContactCard data={{}}></GlassContactCard> */}
      {props.contactList.length>0 && props.contactList.map((contact, index)=>{
          return(
            <div className={`contact-list__card`} onClick={()=>{handleChatChange(contact.username, index)}} key={index}>
            <GlassContactCard data={contact}></GlassContactCard>
            </div>
            // <p>{contact}</p>
        )
      })}

    </div>
  )
}

export default ContactList
