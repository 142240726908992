import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const SwitchAccount = () => {
    const navigate = useNavigate()
  const [accountArray, setAccountArray] = useState([]);

  useEffect(() => {
    const arr = JSON.parse(localStorage.getItem("aerolink_account_array"));
    console.log(arr);
    setAccountArray(arr);
  }, []);



  const handleAccountChange = (account)=>{
    const authToken = account.authToken;
    const data = account.data;
    localStorage.setItem("aerolink_current_authToken", authToken);
      localStorage.setItem("aerolink_current_user_data", JSON.stringify(data));
      
    navigate("/dashboard")
  }
  return (
    <div className="switch-account">
      <h1>Switch Accounts</h1>
      <div className="switch-account__card-group">
        {accountArray.map((account) => {
          return (
            <div className="switch-account__card" onClick={()=>{handleAccountChange(account)}}>
              <p>{account.data.username}</p>
            </div>
          );
        })}
      </div>
      <Link to={"/login"}>Add another account</Link>
    </div>
  );
};

export default SwitchAccount;
