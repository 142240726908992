import React, { useState } from 'react'
import Color from '../../functions/colors'
import ColorContext from './ColorContext'

const ColorState = (props) => {
    const [primaryColor, setPrimaryColor] = useState(new Color(`hsl(200, 100%, 50%)`))
    const [secondaryColor, setSecondaryColor] = useState(new Color(`hsl(30, 100%, 50%)`))

    const exportObj = {primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor}
  return (
    <ColorContext.Provider value={exportObj}>
      {props.children}
    </ColorContext.Provider>
  )
}

export default ColorState
