import React from 'react'
import aerolink from "../../assets/logos/AeroLink.png"
// import { SideNavBar } from '../../components'
// import { noAuthNavBar } from '../../data/navbar.data'

const HomePage = (props) => {
  return (
    <div className='homepage'>
      {/* <SideNavBar color={"#ff0050"} menu={noAuthNavBar}></SideNavBar> */}

      <section className="homepage__header">
        <div className="homepage__logo">
            <img src={aerolink} alt="" className="homepage__logo__image" />
            <div className="homepage__logo__brand">
              <h1 className="homepage__logo__webname">AeroLink</h1>
              <h3 className="homepage__logo__company-name">from WebSync</h3>
            </div>
        </div>
        {props.child}
      </section>
    </div>
  )
}

export default HomePage
