import React from 'react'
// import aerolink from "../../assets/logos/AeroLink.png"
// import { SideNavBar } from '../../components'
// import { noAuthNavBar } from '../../data/navbar.data'

const ContentContainer = (props) => {
  
  return (
    <div className='content-container'>
      {/* <SideNavBar color={"#ff0050"} menu={noAuthNavBar}></SideNavBar> */}

      <section className="content-container__header">
        {/* {showLogo && <div className="content-container__logo">
            <img src={aerolink} alt="" className="content-container__logo__image" />
            <div className="content-container__logo__brand">
              <h1 className="content-container__logo__webname">AeroLink</h1>
              <h3 className="content-container__logo__company-name">from WebSync</h3>
            </div>
        </div>} */}
        {props.child}
      </section>
    </div>
  )
}

export default ContentContainer
