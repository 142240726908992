import React from 'react'

const GlassPopUp = (props) => {
  return (
    <div className={`glass-pop-up ${props.class}`}>
      {props.child}
    </div>
  )
}

export default GlassPopUp
