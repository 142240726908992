import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ContactSettings, ProfileForm } from '../../containers'

const SettingsPage = () => {
  const settingList = [
    {
      text: "profile",
      link: "/settings/profile"
    },
    {
      text: "contacts",
      link: "/settings/contacts"
    },
  ]
    const settingsTypes = {
        contacts: <ContactSettings></ContactSettings>,
        profile: <ProfileForm></ProfileForm>
    }
    const {setting_type} = useParams()
  return (
    <div className='settings'>
        <div className="settings__left">
          {settingList.map(item=>{
            return(
              <Link to={item.link} key={JSON.stringify(item)}>{item.text}</Link>
            )
          })}
        </div>
        <div className="settings__right">
            {settingsTypes[setting_type]}
        </div>
      
    </div>
  )
}

export default SettingsPage
