export const checkLoginStatus = async () => {
  try {
    const token = localStorage.getItem("aerolink_current_authToken");
    const userData = JSON.parse(
      localStorage.getItem("aerolink_current_user_data")
    );

    if(!token || !userData){
        return
    }

    const res = await fetch(
      `${process.env.REACT_APP_AEROLINK_API_URL}/account/auth`,
      {
        headers: {
          authorization: token,
        },
        method: "GET",
      }
    );

    const response = await res.json();
    if(response.data!==userData.email){
        return false;
    }
    return true;
  } catch (error) {
    return false
  }
};
