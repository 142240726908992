import React, { useEffect, useState } from "react";
import { GlassTextBox } from "../../components";

const ChatView = (props) => {
    const [text, setText]=useState("")
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (props.currentChat && props.currentUser) {
      fetch(
        `${process.env.REACT_APP_AEROLINK_API_URL}/chat/message?from=${props.currentUser}&to=${props.currentChat}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (!response.status) {
            setMessages([])
            // alert(response.message);
          } else {
            setMessages(response.data);
          }
        });
    }
  }, [props.currentChat, props.currentUser]);


  const handleSendMessage = async (e)=>{
    e.preventDefault()
    console.log(JSON.stringify({from: props.currentUser, to: props.currentChat, message: text}))
    const res = await fetch(`${process.env.REACT_APP_AEROLINK_API_URL}/chat/message`, {
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({from: props.currentUser, to: props.currentChat, message: text}),
        method: "POST"
    })

    const response = await res.json();

    if(!response.status){
       return alert(response.message)
    }
    
    const msgs = [...messages]
    msgs.push(response.data)
    setMessages(msgs)

    props.socket.current.emit("send-msg", {
        from: props.currentUser,
        to: props.currentChat,
        message: text
    })

    setText("")
  }

  useEffect(()=>{
    if(props.socket.current){
        props.socket.current.on("msg-receive", (msg)=>{
            console.log("received")
            const msgs = [...messages]
            msgs.push(msg)
            setMessages(msgs)
        })
    }
  },[messages, props.socket])
  if(props.currentChat){
    return (
      <div className="chat-view">
        <div className="chat-view__message-view">
        chatview
        {props.currentChat ? props.currentChat : "hello"}
        {props.currentUser ? props.currentUser : "World"}
        <br />
        {/* {JSON.stringify(messages)} */}
        {messages.map(message=>{
          return(
              <div className="chat-view__message-container" key={JSON.stringify(message)}>
              <div className={`chat-view__message__card ${message.sender===props.currentUser? "chat-view__message__card--sent":"chat-view__message__card--received"}`}>
                  <p className="chat-view__message__text">{message.message}</p>
              </div>
              </div>
          )
        })}
        </div>
        <form className="chat-view__form" onSubmit={handleSendMessage}>
          <div className="chat-view__form__input">
          <GlassTextBox type={"text"} placeholder={"type here"} value={text} setValue={setText}></GlassTextBox>
          </div>
          <div className="chat-view__form__btn">
          <button type="submit">Send</button>
          </div>
        </form>
      </div>
    );
  }
  else{
    return(
      <h1>{`Welcome, ${props.currentUser? props.currentUser: ""}`}</h1>
    )
  }
};

export default ChatView;
