import React from "react";
import "./_glass_textbox.scss"
import Color from "../../../../functions/colors";

const GlassTextBox = (props) => {

  const color = new Color(props.color);

  const handleValueChange = (e) => {
    const indicator = document.getElementById(`${props.id}Indicator`);
    props.setValue(e.target.value);
    if (!props.validator) {
      if (e.target.value !== "") {
        indicator.classList.add("glass-textbox__indicator--neutral");
      } else {
        indicator.classList.remove("glass-textbox__indicator--neutral");
      }
      return;
    }

    if (props.validator(e.target.value)) {
      indicator.classList.add("glass-textbox__indicator--correct");
      indicator.classList.remove("glass-textbox__indicator--neutral");
      indicator.classList.remove("glass-textbox__indicator--incorrect");
    } else {
      indicator.classList.remove("glass-textbox__indicator--correct");
      indicator.classList.remove("glass-textbox__indicator--neutral");
      indicator.classList.add("glass-textbox__indicator--incorrect");
    }
  };
  return (
    <label htmlFor={props.id} className={`glass-textbox ${props.class}`} style={{
      "--h": `${color.h}`,
      "--s": `${color.s}%`,
      "--l": `${color.l}%`,
      "--opacity": `${props.opacity? props.opacity : 0.1}`
    }}>
      <input
        type={props.type}
        name={props.id}
        id={props.id}
        className={`glass-textbox__input`}
        value={props.value}
        onChange={handleValueChange}
        placeholder={props.placeholder}
      />
      <p className="glass-textbox__placeholder">{props.placeholder}</p>
      <div
        className="glass-textbox__indicator"
        id={`${props.id}Indicator`}
      ></div>
    </label>
  );
};

export default GlassTextBox;
