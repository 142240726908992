import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button3D } from '../../components'
import ColorContext from '../../context/ColorContext/ColorContext'
import { checkLoginStatus } from '../../functions/auth.function'



const Title = () => {
  const colorContext = useContext(ColorContext)
  const navigate = useNavigate()

  useEffect(() => {
    checkLoginStatus()
      .then((isLoggedIn) => {
        if (isLoggedIn) {
          // console.log("User is logged in.");
          navigate("/dashboard")
        } else {
        }
      })
      .catch((error) => {
        console.error("Error checking login status:", error);
      });
  }, [navigate]);


  return (
    <div className='title'>
      <h1 className='title__heading'><span>A</span>ero<span>l</span>ink</h1>
      <h3 className='title__tagline'>Bridging Conversations, Beyond Borders</h3>
      <div className="title__btn-group">
      {/* <Button3D text={"test"}></Button3D> */}
      <Button3D text={"login"} focus={true} method={()=>{navigate("/login")}} color={colorContext.secondaryColor}></Button3D>
      <Button3D text={"register"} focus={false} method={()=>{navigate("/register")}} color={colorContext.secondaryColor}></Button3D>
      </div>
    </div>
  )
}

export default Title
