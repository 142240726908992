import React from "react";
import {hsl} from "../../../functions/colors"

const HeadingWithLine = (props)=>{
    const color = hsl(props.color)

    return (
        <div className="heading-with-line">
            <span className="heading-with-line__line heading-with-line__line--left" style={{backgroundColor: color}}></span>
            <p className="heading-with-line__text" style={{color: color, border: `2px solid ${color}`}}>{props.text}</p>
            <span className="heading-with-line__line heading-with-line__line--right" style={{backgroundColor: color}}></span>
        </div>
    )
}



export default HeadingWithLine;